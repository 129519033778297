import MuiTooltip, { tooltipClasses } from '@mui/material/Tooltip';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import { ChildrenType } from 'types';
import { makeStyles } from 'utils/make-styles';

const useStyles = makeStyles()(
  {
    tooltip: {
      [`&.${tooltipClasses.tooltip}`]: {
        whiteSpace: 'pre-line',
      },
    },
  },
  { name: 'bo-Tooltip' }
);
export function Tooltip({ title, customClasses, titleId, titleValues, children, ...props }) {
  const { formatMessage } = useIntl();
  const { classes } = useStyles();
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('sm'));

  return (
    <MuiTooltip
      classes={{
        tooltip: {
          ...classes.tooltip,
        },
        ...customClasses,
      }}
      leaveTouchDelay={15000}
      placement={isDesktop ? 'right' : 'top'}
      title={titleId ? formatMessage({ id: titleId }, titleValues) : title}
      arrow
      disableFocusListener
      {...props}>
      {children}
    </MuiTooltip>
  );
}

Tooltip.propTypes = {
  title: PropTypes.node,
  titleId: PropTypes.string,
  titleValues: PropTypes.shape(),
  children: ChildrenType,
  customClasses: PropTypes.shape(),
};

Tooltip.defaultProps = {
  title: undefined,
  titleId: undefined,
  titleValues: undefined,
  children: undefined,
  customClasses: undefined,
};
